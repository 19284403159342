<template>
  <section id="dashboard">
    <v-card>
      <v-card-text></v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {

}
</script>